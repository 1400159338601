export function getFileFromCache(state) {
    if (state.descriptionObjects.file?.dataUrl) {
        const { filename, dataUrl } = state.descriptionObjects.file;
        const fileString = decodeURIComponent(atob(dataUrl.split(',')[1]).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join('')); // b64DecodeUnicode
        const fileBlob = new File([fileString], filename, { type: 'image/svg+xml' });
        return {
            uid: Date.now(),
            name: filename,
            status: 'done',
            originFileObj: fileBlob,
        };
    } else {
        return undefined;
    }
}