import { Table, Card, List, Avatar, Row, Col } from 'antd';
import React, { useState } from 'react';
import { ObjectTypes } from "../types";
import './index.css';

const FaciesTable = ({ data }) => {
    const { FACIES_ASSOCIATION } = ObjectTypes;
    const [selectedFaciesAssoc, setSelectedFaciesAssoc] = useState({});

    //Filter data to get only FaciesAssociationTypes
    const newArray = data.filter(e => {
        return e.geologicalObjectType === FACIES_ASSOCIATION;
    })

    const columns = [
        {
            title: 'Associação de Fácies',
            dataIndex: 'title',
            key: 'key',
            render: (text) => <span>{text}</span>,
        },
    ];

    return (
        <Row gutter={16}>
            <Col span={6}>
                <Table
                    columns={columns}
                    dataSource={newArray}
                    pagination = {false}
                    size="small"
                    onRow={(record) => {
                        return {
                            onClick: () => { setSelectedFaciesAssoc(record) },
                        };
                    }}
                />
            </Col>
            <Col span={9}>
                {selectedFaciesAssoc.properties && <FaciesItem properties={selectedFaciesAssoc.properties} />}
            </Col>
        </Row>

    )
}

const FaciesItem = ({ properties }) => {

    const propertiesNameDict = {
        typeFaciesAssociation: "Tipo",
        compLithology: "Litologia composicional",
        granulometricMode: "Moda granulométrica",
        sphericity: "Esfericidade",
        rounding: "Arredondamento",
        selection: "Seleção",
        depositionalStructure: "Estrutura deposicional",
        deformationStructure: "Estrutura de deformação",
        bioturbation: "Bioturbação",
        topAndBaseStructure: "Estrutura de topo e base",
        tectonicStructure: "Estrutura tectônica",
    }

    const listData = Object.keys(propertiesNameDict).map(key => {
        const label = propertiesNameDict[key];
        let value = "Valor não informado"
        
        if (properties[key])
            if (key === 'typeFaciesAssociation')  
                value = properties[key].at(-1);
            else
                value = properties[key];

        return { label: label, value: value }
    })


    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={listData}
                renderItem={(item) => (
                    <List.Item>
                        <strong>{item.label}</strong> : {item.value}
                    </List.Item>
                )}
            />
        </>
    );
}

export default FaciesTable;