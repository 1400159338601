export const actions = {
    SET_DESCRIPTION_INFORMATION: "SET_DESCRIPTION_INFORMATION",
    SET_DESCRIPTION_OBJECTS: "SET_DESCRIPTION_OBJECTS",
    ADD_DESCRIPTION_OBJECT: "ADD_DESCRIPTION_OBJECT",
    UPDATE_OBJECT_STRUCTURE: "UPDATE_OBJECT_STRUCTURE",
    SET_SELECTED_OBJECT: "SET_SELECTED_OBJECT",
    SET_SELECTED_OBJECT_PROPERTIES: "SET_SELECTED_OBJECT_PROPERTIES",
    DELETE_DESCRIPTION_OBJECT: "DELETE_DESCRIPTION_OBJECT",
    SET_OBJECT_PROPERTIES: "SET_OBJECT_PROPERTIES",
    UPDATE_DESCRIPTION_OBJECTS_FEEDBACK: "UPDATE_DESCRIPTION_OBJECTS_FEEDBACK",
    SELECTING_CONTACT: "SELECTING_CONTACT",
    CHECKED_OBJECTS_CONTACTS: "CHECKED_OBJECTS_CONTACTS",
    UPDATE_CONTACTS: "UPDATE_CONTACTS",
    SET_SELECTED_CONTACT: "SET_SELECTED_CONTACT",
    SET_FILE: "SET_FILE",
    SET_DESCRIPTION_DATA_RETRIEVAL: "SET_DESCRIPTION_DATA_RETRIEVAL",
    DELETE_DESCRIPTION_CHARTS: "DELETE_DESCRIPTION_CHARTS",
    UPDATE_DESCRIPTION_CHARTS: "UPDATE_DESCRIPTION_CHARTS",
    SET_DESCRIPTION_CHARTS: "SET_DESCRIPTION_CHARTS",
    SET_EDITING_DESCRIPTION: "SET_EDITING_DESCRIPTION"
}

export const setEditingDescriptionAction = (isEditing) => ({
    type: actions.SET_EDITING_DESCRIPTION,
    value: isEditing,
});

export const setDescriptionInformationAction = (descriptionInformation) => ({
    type: actions.SET_DESCRIPTION_INFORMATION,
    value: descriptionInformation,
});

export const setDescriptionObjectsAction = (descriptionObjects) => ({
    type: actions.SET_DESCRIPTION_OBJECTS,
    value: descriptionObjects,
});

export const addDescriptionObject = (descriptionObject) => ({
    type: actions.ADD_DESCRIPTION_OBJECT,
    value: descriptionObject,
});

export const updateObjectStructure = (objectStructure) => ({
    type: actions.UPDATE_OBJECT_STRUCTURE,
    value: objectStructure,
});

export const setSelectedObject = (selectedObject) => ({
    type: actions.SET_SELECTED_OBJECT,
    value: selectedObject,
});

export const deleteDescriptionObject = (updatedInfo) => ({
    type: actions.DELETE_DESCRIPTION_OBJECT,
    value: updatedInfo,
});

export const setObjectProperties = (objectProperties) => ({
    type: actions.SET_OBJECT_PROPERTIES,
    value: objectProperties,
});

export const setSelectedObjectProperties = (objectProperties) => ({
    type: actions.SET_SELECTED_OBJECT_PROPERTIES,
    value: objectProperties,
});

export const updateDescriptionObjectsFeedback = (descriptionObjectsFeedback) => ({
    type: actions.UPDATE_DESCRIPTION_OBJECTS_FEEDBACK,
    value: descriptionObjectsFeedback,
});


export const setSelectingContact = (selectingContact) => ({
    type: actions.SELECTING_CONTACT,
    value: selectingContact,
});


export const setCheckedsObjectsContacts= (checkedsObjectsContacts) => ({
    type: actions.CHECKED_OBJECTS_CONTACTS,
    value: checkedsObjectsContacts,
});

export const updateContacts= (contacts) => ({
    type: actions.UPDATE_CONTACTS,
    value: contacts,
});

export const setSelectedContact = (selectedContact) => ({
    type: actions.SET_SELECTED_CONTACT,
    value: selectedContact,
});

export const setFile = (file) => ({
    type: actions.SET_FILE,
    value: file,
});

export const setDescriptionDataRetrieval = (descriptionDataRetrieval) => ({
    type: actions.SET_DESCRIPTION_DATA_RETRIEVAL,
    value: descriptionDataRetrieval,
});

export const updateDescriptionDataCharts = (updateDescriptionDataCharts) => ({
    type: actions.UPDATE_DESCRIPTION_CHARTS,
    value: updateDescriptionDataCharts,
});

export const setDescriptionDataCharts = (descriptionDataCharts) => ({
    type: actions.SET_DESCRIPTION_CHARTS,
    value: descriptionDataCharts,
});

export const deleteDescriptionCharts = (indexDescriptionDataCharts) => ({
    type: actions.DELETE_DESCRIPTION_CHARTS,
    value: indexDescriptionDataCharts,
});

