export const ObjectTypes = {
    UNIT: 'Unidade',
    ARCH_ELEMENT: 'ElemArquitetural',
    FACIES_ASSOCIATION: 'AssocFacies'
}

export const ScaleTypes = {
    COMPLEX: 'Complexo',
    COMPOUND: 'Composto',
    INDIVIDUAL: 'Indivíduo',
}

export const ArchitecturalElementTypes = {
    CHANNEL: 'Canal',
    LOBE_SPRAWL: 'Lobo/Espraiamento',
    EXTRAVASATION: 'Extravasamento',
    MASS_TRANSPORT: 'Transporte de massa',
    CONDENSED_SECTION: 'Seção condensada',
}

export const UnitTypes = {
    STRATIGRAPHIC_UNIT: 'UnidEstat',
    INTERUNIT: 'Interunidade',
    COMPLEX_SET: 'Conjunto de Complexos',
}

export const ArchitecturalElementOptions = [
    {
        label: 'Canal',
        value: 'Canal',
        key: 'Canal',
    },
    {
        label: 'Lobo/Espraiamento',
        value: 'Lobo/Espraiamento',
        key: 'Lobo/Espraiamento',
    },
    {
        label: 'Extravasamento',
        value: 'Extravasamento',
        key: 'Extravasamento',
    },
    {
        label: 'Transporte de massa',
        value: 'Transporte de massa',
        key: 'Transporte de massa',
    },
    {
        label: 'Seção condensada',
        value: 'Seção condensada',
        key: 'Seção condensada',
    },
];