import React, { useEffect, useState, useContext, useMemo } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HC_more from 'highcharts/highcharts-more';
import bellcurve from "highcharts/modules/histogram-bellcurve";
import HighchartsHeatMap from "highcharts/modules/heatmap";
import { Row, Col, Radio, Space, Select } from 'antd';
import { ObjectTypes, ArchitecturalElementTypes as ArchType } from "../Description/CreateDescription/DescriptionObjects/types"
import { store } from '../Store';
import { useAxiosGet } from '../../hooks/useAxios';
import { architecturalTypeColors } from '../Description/CreateDescription/DescriptionObjects/Properties/chartColors';

bellcurve(Highcharts);
HC_more(Highcharts);
HighchartsHeatMap(Highcharts);

export const ScatterPlot = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const LOG = 1, LINEAR = 2;
    const [value, setValue] = useState(1);
    const onChange = (e) => {
        setValue(e.target.value);
    };

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'scatter',
            zoomType: 'xy'
        },
        title: {
            text: 'Razão de Aspecto',
            align: 'left'
        },
        xAxis: {
            title: {
                text: 'Largura'
            },
            labels: {
                format: '{value} m'
            },
            startOnTick: true,
            endOnTick: true,
            showLastLabel: true,
            min: 1,
        },
        yAxis: {
            title: {
                text: 'Altura'
            },
            labels: {
                format: '{value} m'
            },
            min: 1,
        },
        legend: {
            enabled: true
        },
        plotOptions: {
            scatter: {
                marker: {
                    radius: 4.5,
                    symbol: 'circle',
                    states: {
                        hover: {
                            enabled: true,
                            lineColor: 'rgb(100,100,100)'
                        }
                    }
                },
                states: {
                    hover: {
                        marker: {
                            enabled: false
                        }
                    }
                }
            }
        },
        tooltip: {
            pointFormat: ' {point.name} <br/> Largura: {point.x:.1f} m <br/> Altura: {point.y:.1f} m'
        },
    });

    const getData = (data) => {
        const result = {
            [ArchType.CHANNEL]: { name: ArchType.CHANNEL, data: [], color: architecturalTypeColors[ArchType.CHANNEL] },
            [ArchType.LOBE_SPRAWL]: { name: ArchType.LOBE_SPRAWL, data: [], color: architecturalTypeColors[ArchType.LOBE_SPRAWL] },
            [ArchType.EXTRAVASATION]: { name: ArchType.EXTRAVASATION, data: [], color: architecturalTypeColors[ArchType.EXTRAVASATION] },
            [ArchType.MASS_TRANSPORT]: { name: ArchType.MASS_TRANSPORT, data: [], color: architecturalTypeColors[ArchType.MASS_TRANSPORT] },
            [ArchType.CONDENSED_SECTION]: { name: ArchType.CONDENSED_SECTION, data: [], color: architecturalTypeColors[ArchType.CONDENSED_SECTION] },
        };

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    title,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                } = properties || {};

                if (
                    geologicalObjectType === ObjectTypes.ARCH_ELEMENT &&
                    result[architecturalElementType]
                ) {
                    if (width !== undefined && thickness !== undefined) {
                        result[architecturalElementType].data.push({ x: width, y: thickness, name: title });
                    }
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return Object.values(result);
    };

    useEffect(() => {
        const type = value === LINEAR ? 'linear' : 'logarithmic';
        const tickInterval = value === LINEAR ? '' : 1;
        const minorTickInterval = value === LINEAR ? '' : 0.1;

        setChartOptions({
            series: getData(descriptionDataRetrieval),
            xAxis: {
                ...chartOptions.xAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            },
            yAxis: {
                ...chartOptions.yAxis,
                type: type,
                tickInterval: tickInterval,
                minorTickInterval: minorTickInterval,
            }
        });

    }, [value, descriptionDataRetrieval])

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Radio.Group onChange={onChange} value={value}>
                        <Space direction="vertical">
                            <Radio value={1}>Logarítimica</Radio>
                            <Radio value={2}>Linear </Radio>
                        </Space>
                    </Radio.Group>
                </Col>
            </Row>
        </>
    )
};

export const PieChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const getData = (data) => {
        const result = {
            [ArchType.CHANNEL]: { name: ArchType.CHANNEL, y: 0, color: architecturalTypeColors[ArchType.CHANNEL] },
            [ArchType.LOBE_SPRAWL]: { name: ArchType.LOBE_SPRAWL, y: 0, color: architecturalTypeColors[ArchType.LOBE_SPRAWL] },
            [ArchType.EXTRAVASATION]: { name: ArchType.EXTRAVASATION, y: 0, color: architecturalTypeColors[ArchType.EXTRAVASATION] },
            [ArchType.MASS_TRANSPORT]: { name: ArchType.MASS_TRANSPORT, y: 0, color: architecturalTypeColors[ArchType.MASS_TRANSPORT] },
            [ArchType.CONDENSED_SECTION]: { name: ArchType.CONDENSED_SECTION, y: 0, color: architecturalTypeColors[ArchType.CONDENSED_SECTION] },
        };

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if (
                    geologicalObjectType === ObjectTypes.ARCH_ELEMENT &&
                    result[architecturalElementType]
                ) {
                    result[architecturalElementType].y = result[architecturalElementType].y + 1
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return Object.values(result);
    };

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie'
        },
        title: {
            text: undefined
        },
        tooltip: {
            pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                }
            }
        },
        series: [{
            name: 'Elemento Arquitetural',
            colorByPoint: true,
            data: getData(descriptionDataRetrieval),
        }]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export const StackedHorizontalCharts = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    let categories = [];
    let series = [];

    // initializng plot variables
    const dataLength = descriptionDataRetrieval.length;
    const archTypeStrings = Object.values(ArchType);
    Object.values(ArchType).forEach(e => series.push({ name: e, data: Array(dataLength).fill(0) }))
    descriptionDataRetrieval.forEach((e, index) => categories.push(`Descrição ${index + 1}`))

    const getSeries = (data) => {
        let descriptionIndex = 0;
        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if (geologicalObjectType === ObjectTypes.ARCH_ELEMENT) {
                    let found = archTypeStrings.indexOf(architecturalElementType);
                    if (found !== -1)
                        series[found].data[descriptionIndex]++;
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
            descriptionIndex++
        });
    };

    getSeries(descriptionDataRetrieval);

    const options = {
        chart: {
            type: 'bar'
        },
        title: {
            text: ''
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            min: 0,
            title: {
                text: 'Porcentagem'
            }
        },
        tooltip: {
            pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
        },
        plotOptions: {
            bar: {
                stacking: 'percent'
            }
        },
        series: series,
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};

export const HistogramChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const WIDTH = "width", THICKNESS = "thickness", AREA = "area";
    const [variable, setVariable] = useState(WIDTH);
    const [archType, setArchType] = useState("Canal");
    const onChangeVariable = (value) => {
        setVariable(value);
    };

    const onChangeArchType = (value) => {
        setArchType(value);
    };

    const getData = (data) => {
        const result = {
            [ArchType.CHANNEL]: { width: [], thickness: [], area: [] },
            [ArchType.LOBE_SPRAWL]: { width: [], thickness: [], area: [] },
            [ArchType.EXTRAVASATION]: { width: [], thickness: [], area: [] },
            [ArchType.MASS_TRANSPORT]: { width: [], thickness: [], area: [] },
            [ArchType.CONDENSED_SECTION]: { width: [], thickness: [], area: [] },
        };

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                    area,
                } = properties || {};

                if (
                    geologicalObjectType === ObjectTypes.ARCH_ELEMENT &&
                    result[architecturalElementType]
                ) {
                    if (width !== undefined && thickness !== undefined && area !== undefined) {
                        result[architecturalElementType].width.push(width);
                        result[architecturalElementType].thickness.push(thickness);
                        result[architecturalElementType].area.push(area);
                    }
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return result;
    };

    const [filteredData, setFilteredData] = useState(getData(descriptionDataRetrieval));
    const [chartOptions, setChartOptions] = useState({
        title: {
            text: 'Váriavel x Elemento Arquitetural'
        },

        xAxis: [{
            title: { text: 'Dados' },
            alignTicks: false
        }, {
            title: { text: 'Histograma' },
            alignTicks: false,
            opposite: true
        }],

        yAxis: [{
            title: { text: 'Dados' }
        }, {
            title: { text: 'Histograma' },
            opposite: true
        }],

        plotOptions: {
            histogram: {
                accessibility: {
                    point: {
                        valueDescriptionFormat: '{index}. {point.x:.3f} to {point.x2:.3f}, {point.y}.'
                    }
                }
            }
        },

        series: [{
            name: 'Histograma',
            type: 'histogram',
            xAxis: 1,
            yAxis: 1,
            baseSeries: 's1',
            zIndex: -1
        }, {
            name: 'Dados',
            type: 'scatter',
            id: 's1',
            data: filteredData[archType][variable],
            marker: {
                radius: 1.5
            }
        }]
    })

    useEffect(() => {
        setFilteredData(getData(descriptionDataRetrieval));
    }, [descriptionDataRetrieval])

    useEffect(() => {
        if (filteredData[archType][variable].length !== 0)
            setChartOptions(old => ({
                ...old,
                series: [{
                    name: 'Histograma',
                    type: 'histogram',
                    xAxis: 1,
                    yAxis: 1,
                    baseSeries: 's1',
                    zIndex: -1
                }, {
                    name: 'Dados',
                    type: 'scatter',
                    id: 's1',
                    data: filteredData[archType][variable],
                    marker: {
                        radius: 1.5
                    }
                }]
            }));
        else
            setChartOptions(old => ({
                ...old,
                series: [{
                    name: 'Histograma',
                    type: 'histogram',
                    xAxis: 1,
                    yAxis: 1,
                    baseSeries: 's1',
                    zIndex: -1
                }, {
                    name: 'Dados',
                    type: 'column',
                    id: 's1',
                    data: filteredData[archType][variable],
                    marker: {
                        radius: 1.5
                    }
                }]
            }));

    }, [filteredData])


    useEffect(() => {
        if (filteredData[archType][variable].length === 0)
            return;

        setChartOptions((prevChartOptions) => {
            // Clone the existing series array
            const updatedSeries = [...prevChartOptions.series];

            // Update the data property of the second series
            updatedSeries[1] = {
                ...updatedSeries[1], // Clone the existing object
                data: filteredData[archType][variable], // Update the data property
            };

            // Return the updated chartOptions with the modified series array
            return {
                ...prevChartOptions, // Clone the existing chartOptions
                series: updatedSeries, // Update the series array
            };
        });
    }, [archType, variable]);

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Variável"
                            defaultValue={"Largura"}
                            value={variable}
                            onChange={onChangeVariable}
                            options={[
                                {
                                    value: WIDTH,
                                    label: 'Largura',
                                },
                                {
                                    value: THICKNESS,
                                    label: 'Espessura',
                                },
                                {
                                    value: AREA,
                                    label: 'Área',
                                },
                            ]}
                        >
                        </Select>
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Elemento"
                            defaultValue={"Canal"}
                            value={archType}
                            onChange={onChangeArchType}
                        >
                            {Object.values(ArchType).map((option, index) => (
                                <Select.Option key={index} value={option}>
                                    {option}
                                </Select.Option>
                            ))}
                        </Select>
                    </Space>
                </Col>
            </Row>
        </>
    )
};

export const BarChart = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const categories = Object.values(ArchType);

    const getData = (data) => {
        const result = Array(categories.length).fill(0)

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                if (geologicalObjectType === ObjectTypes.ARCH_ELEMENT) {
                    let found = categories.indexOf(architecturalElementType);
                    if (found !== -1)
                        result[found]++;
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return result;
    };

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: 'Frequência por elemento arquitetural',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            title: {
                text: 'Frequência',
            },
        },
        series: [
            {
                name: 'Dados',
                data: getData(descriptionDataRetrieval),
            },
        ],
    };

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
};


export const HeatMap = () => {
    //Geting data from backend
    const descriptionInfoURL = "http://api-geoserver.inf.ufrgs.br/description/data";
    const urlGeologicalObjects = 'geologicalObjects';
    const urlArchitecturalElement = 'architecturalElement';
    const urlBaseGeometry = 'bottomGeometry';
    const urlGeometryType = 'typeGeometry';
    const urlTopGeometry = 'topGeometry';
    const { data: architecturalElementData, error: architecturalElementDataError, loaded: architecturalElementDataLoaded } = useAxiosGet(descriptionInfoURL, sessionStorage);

    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const architecturalElement = useMemo(() => {
        const generateList = (data) => {
            let tempList = [];
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                let newNode = {}
                newNode.title = node.labels.pt[0]
                newNode.value = node.iri;
                if (node.types)
                    newNode.children = generateList(node.types)
                else
                    newNode.children = [];
                tempList.push(newNode);
            }
            return tempList;
        };

        let parsedArchitecturalElementFields = {};

        if (architecturalElementData) {
            let geometryType = architecturalElementData[urlGeologicalObjects][urlArchitecturalElement][urlGeometryType].types;
            parsedArchitecturalElementFields.geometryType = generateList(geometryType);

            return parsedArchitecturalElementFields;
        }


    }, [architecturalElementData]);

    //Geometry data
    const categories = Object.values(ArchType);

    let geometriesList = [];

    const addToUniqueList = (list, element) => {
        if (!list.includes(element)) {
            list.push(element);
        }
    }

    const findTitleByValue = (dictionaryList, targetValue) => {
        for (let i = 0; i < dictionaryList.length; i++) {
            const dictionary = dictionaryList[i];
            if (dictionary.value === targetValue) {
                return dictionary.title;
            }
        }

        return null; // Return null if the target value is not found in any dictionary.
    }

    const getData = (data) => {
        //const result = Array(categories.length).fill(0)

        const result = Object.fromEntries(categories.map(category => [category, {}]));
        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    children,
                    architecturalElementType,
                } = node;

                //if (geologicalObjectType === ObjectTypes.ARCH_ELEMENT) {
                let found = categories.indexOf(architecturalElementType);
                if (found !== -1 && architecturalElementDataLoaded)
                    if ("typeGeometry" in node["properties"]) {
                        let typeGeometry = findTitleByValue(architecturalElement.geometryType, node["properties"]["typeGeometry"]);

                        if (!(typeGeometry in result[architecturalElementType])) {
                            result[architecturalElementType][typeGeometry] = 1;
                            addToUniqueList(geometriesList, typeGeometry);
                        }
                        else {
                            result[architecturalElementType][typeGeometry]++;
                        }
                    }
                    else {
                        let other = "não informada";
                        if (!(other in result[architecturalElementType])) {
                            result[architecturalElementType][other] = 1;
                            addToUniqueList(geometriesList, other);
                        }
                        else {
                            result[architecturalElementType][other]++;
                        }
                    }
                //}

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        const matriz = [];

        categories.forEach((category, categoryIndex) => {
            geometriesList.forEach((geometry, geometryIndex) => {
                if (result[category] && result[category][geometry] !== undefined) {
                    const valor = result[category][geometry];
                    matriz.push([categoryIndex, geometryIndex, valor]);
                }
            });
        });

        return matriz;
    };

    let histogramData = getData(descriptionDataRetrieval);

    const options = {
        chart: {
            type: 'heatmap',
        },
        title: {
            text: 'Frequência de tipo de geometria por elemento arquitetural',
        },
        xAxis: {
            categories: categories,
        },
        yAxis: {
            categories: geometriesList,
            title: {
                text: 'Frequência',
            },
        },
        colorAxis: {
            min: 0,
            minColor: '#FFFFFF',
            maxColor: Highcharts.getOptions().colors[0],
            stops: [
                [0, '#FFFFFF'], // Define the color for the lowest value
                [0.5, '#FF0000'], // Define the color for the middle value
                [1, '#000000'] // Define the color for the highest value
            ],
            width: 100
        },
        series: [
            {
                name: 'Dados',
                data: histogramData,
                dataLabels: {
                    enabled: true,
                    color: '#000000',
                    formatter: function () {
                        return this.point.value; // Display only the value
                    }
                }
            },
        ],
        tooltip: {
            enabled: false
        },
    };

    return (

        <><span>
            {!architecturalElementDataLoaded ? (
                "Carregando..."
            ) : (
                <HighchartsReact
                    highcharts={Highcharts}
                    options={options} />
            )}
        </span></>
    );
};

export const BoxPlot = () => {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const descriptionDataRetrieval = state.descriptionDataRetrieval;

    const WIDTH = "width", THICKNESS = "thickness", AREA = "area";
    const [variable, setVariable] = useState(WIDTH);
    const onChangeVariable = (value) => {
        setVariable(value);
    };

    const categories = Object.values(ArchType);

    const getData = (data) => {
        const result = {
            width: { [ArchType.CHANNEL]: [], [ArchType.LOBE_SPRAWL]: [], [ArchType.EXTRAVASATION]: [], [ArchType.MASS_TRANSPORT]: [], [ArchType.CONDENSED_SECTION]: [] },
            thickness: { [ArchType.CHANNEL]: [], [ArchType.LOBE_SPRAWL]: [], [ArchType.EXTRAVASATION]: [], [ArchType.MASS_TRANSPORT]: [], [ArchType.CONDENSED_SECTION]: [] },
            area: { [ArchType.CHANNEL]: [], [ArchType.LOBE_SPRAWL]: [], [ArchType.EXTRAVASATION]: [], [ArchType.MASS_TRANSPORT]: [], [ArchType.CONDENSED_SECTION]: [] },
        };

        const traverseTree = (nodes) => {
            if (!nodes || !Array.isArray(nodes)) return;

            nodes.forEach((node) => {
                if (!node) return;

                const {
                    geologicalObjectType,
                    properties,
                    title,
                    children,
                    architecturalElementType,
                } = node;
                const {
                    width,
                    thickness,
                    area,
                } = properties || {};

                if (geologicalObjectType === ObjectTypes.ARCH_ELEMENT && categories.indexOf(architecturalElementType) !== -1) {
                    if (width !== undefined && thickness !== undefined && area !== undefined) {
                        result.width[architecturalElementType].push(width);
                        result.thickness[architecturalElementType].push(thickness);
                        result.area[architecturalElementType].push(area);
                    }
                }

                if (children && children.length > 0) {
                    traverseTree(children);
                }
            });
        };

        data.forEach((element) => {
            traverseTree(element);
        });

        return result;
    };

    let filteredData = getData(descriptionDataRetrieval);
    const [boxplotData, setBoxplotData] = useState(getBoxPlotData(filteredData));

    useEffect(() => {
        filteredData = getData(descriptionDataRetrieval)
        setBoxplotData(getBoxPlotData(filteredData));

    }, [descriptionDataRetrieval])

    function getBoxPlotData(data) {
        let boxplotData = {
            width: new Array(categories.length).fill([]),
            thickness: new Array(categories.length).fill([]),
            area: new Array(categories.length).fill([])
        }

        // Iterate through keys (width, thickness, area)
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                const subData = data[key];
                // Iterate through sub-arrays (e.g., [ArchType.CHANNEL], [ArchType.LOBE_SPRAWL], ...)
                for (const archType in subData) {
                    if (subData.hasOwnProperty(archType)) {
                        const data = subData[archType];
                        if (data.length > 0) {
                            const boxPlotValues = calculateBoxPlotValues(data);
                            //console.log(`Box plot values for ${key} - ${archType}:`, boxPlotValues);
                            boxplotData[key][categories.indexOf(archType)] = boxPlotValues
                        }
                    }
                }
            }
        }
        return boxplotData;
    }

    // Function to calculate box plot values
    function calculateBoxPlotValues(data) {
        const sortedData = data.sort((a, b) => a - b);
        const n = sortedData.length;
        const median = n % 2 === 0 ? (sortedData[n / 2 - 1] + sortedData[n / 2]) / 2 : sortedData[Math.floor(n / 2)];
        const lowerHalf = sortedData.slice(0, n / 2);
        const upperHalf = sortedData.slice(n / 2 + (n % 2 === 0 ? 0 : 1));
        const q1 = lowerHalf.length % 2 === 0 ? (lowerHalf[lowerHalf.length / 2 - 1] + lowerHalf[lowerHalf.length / 2]) / 2 : lowerHalf[Math.floor(lowerHalf.length / 2)];
        const q3 = upperHalf.length % 2 === 0 ? (upperHalf[upperHalf.length / 2 - 1] + upperHalf[upperHalf.length / 2]) / 2 : upperHalf[Math.floor(upperHalf.length / 2)];
        const min = sortedData[0];
        const max = sortedData[n - 1];

        return [
            min,
            q1,
            median,
            q3,
            max,
        ];
    }

    const [chartOptions, setChartOptions] = useState({
        chart: {
            type: 'boxplot',
        },
        title: {
            text: '',
        },
        legend: {
            enabled: false
        },
        tooltip: {
            formatter: function () {
                const point = this.point;
                return `
                <b>Tipo:</b> ${point.category}<br>
                <b>Máximo:</b> ${point.high.toFixed(2)}<br>
                <b>Q3:</b> ${point.q3.toFixed(2)}<br>
                <b>Mediana:</b> ${point.median.toFixed(2)}<br>
                <b>Q1:</b> ${point.q1.toFixed(2)}<br>
                <b>Mínimo:</b> ${point.low.toFixed(2)}<br>
              `;
            }
        },
        xAxis: {
            categories: categories,
            title: {
                text: 'Tipo de elemento arquitetural',
            },
        },
        yAxis: {
            title: {
                text: 'Valores',
            },
        },
        series: [
            {
                name: 'Box Plot',
                data: boxplotData[variable],
            },
        ],
    })

    useEffect(() => {
        setChartOptions({
            chart: {
                type: boxplotData[variable].every(subList => subList.length === 0) ? "column" : 'boxplot',
            },
            title: {
                text: '',
            },
            legend: {
                enabled: false
            },
            tooltip: {
                formatter: function () {
                    const point = this.point;
                    return `
                    <b>Tipo:</b> ${point.category}<br>
                    <b>Máximo:</b> ${point.high.toFixed(2)}<br>
                    <b>Q3:</b> ${point.q3.toFixed(2)}<br>
                    <b>Mediana:</b> ${point.median.toFixed(2)}<br>
                    <b>Q1:</b> ${point.q1.toFixed(2)}<br>
                    <b>Mínimo:</b> ${point.low.toFixed(2)}<br>
                  `;
                }
            },
            xAxis: {
                categories: categories,
                title: {
                    text: 'Tipo de elemento arquitetural',
                },
            },
            yAxis: {
                title: {
                    text: 'Valores',
                },
            },
            series: [
                {
                    name: 'Box Plot',
                    data: boxplotData[variable],
                },
            ],
        });

    }, [variable, boxplotData]);

    return (
        <>
            <Row>
                <Col span={20}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={chartOptions}
                    />
                </Col>
                <Col span={4}>
                    <Space
                        direction="vertical"
                        size="middle"
                        style={{
                            display: 'flex',
                        }}
                    >
                        <Select
                            style={{ width: 200 }}
                            placeholder="Selecione um tipo de Variável"
                            defaultValue={"Largura"}
                            value={variable}
                            onChange={onChangeVariable}
                            options={[
                                {
                                    value: WIDTH,
                                    label: 'Largura',
                                },
                                {
                                    value: THICKNESS,
                                    label: 'Espessura',
                                },
                                {
                                    value: AREA,
                                    label: 'Área',
                                },
                            ]}
                        >
                        </Select>
                    </Space>
                </Col>
            </Row>
        </>
    )
};