export const faciesAssociationTypes = [
    {
        label: 'Eixo de canal',
        value: 'Eixo de canal',
        key: 'Eixo de canal',
    },
    {
        label: 'Base de canal',
        value: 'Base de canal',
        key: 'Base de canal',
    },
    {
        label: 'Margem de canal',
        value: 'Margem de canal',
        key: 'Margem de canal',
    },
    {
        label: 'Eixo de lobo',
        value: 'Eixo de lobo',
        key: 'Eixo de lobo',
    },
    {
        label: 'Margem de lobo',
        value: 'Margem de lobo',
        key: 'Margem de lobo',
    },
    {
        label: 'Franja de lobo',
        value: 'Franja de lobo',
        key: 'Franja de lobo',
    },
    {
        label: 'Franja distal de lobo',
        value: 'Franja distal de lobo',
        key: 'Franja distal de lobo',
    },
    {
        label: 'Extravasamento interno arenoso',
        value: 'Extravasamento interno arenoso',
        key: 'Extravasamento interno arenoso',
    },
    {
        label: 'Extravasamento interno lamoso',
        value: 'Extravasamento interno lamoso',
        key: 'Extravasamento interno lamoso',
    },
    {
        label: 'Extravasamento externo arenoso',
        value: 'Extravasamento externo arenoso',
        key: 'Extravasamento externo arenoso',
    },
    {
        label: 'Extravasamento externo lamoso',
        value: 'Extravasamento externo lamoso',
        key: 'Extravasamento externo lamoso',
    },
    {
        label: 'Escorregamento arenoso',
        value: 'Escorregamento arenoso',
        key: 'Escorregamento arenoso',
    },
    {
        label: 'Escorregamento lamoso',
        value: 'Escorregamento lamoso',
        key: 'Escorregamento lamoso',
    },
    {
        label: 'Fluxo de detritos arenoso',
        value: 'Fluxo de detritos arenoso',
        key: 'Fluxo de detritos arenoso',
    },
    {
        label: 'Fluxo de detritos lamoso',
        value: 'Fluxo de detritos lamoso',
        key: 'Fluxo de detritos lamoso',
    },
    {
        label: 'Lamito hemipelagico',
        value: 'Lamito hemipelagico',
        key: 'Lamito hemipelagico',
    },
];