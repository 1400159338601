import { useContext, useState, useRef, useEffect } from "react";
import { store } from '../../../../Store';
import { addDescriptionObject, setSelectingContact } from '../../../../Store/actions';
import ObjectsTree from "./ObjectsTree";
import * as FaIcons from 'react-icons/fa';
import * as tbIcons from 'react-icons/tb';
import { AiOutlineColumnWidth } from 'react-icons/ai';
import "./index.css"
import { ObjectTypes, ScaleTypes, UnitTypes, ArchitecturalElementTypes, ArchitecturalElementOptions } from "../types";
import { useNavigate } from "react-router-dom";
import { DownOutlined, SmallDashOutlined, SearchOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Space, Card, Button, Tooltip, Divider, Modal, Form, Input, Select } from 'antd';
import ContactsForm from "../ContactsForm";
import FloatLabel from "../../../../FloatLabel"
import AddObjectModal from "./addObjectModal";

function GeoObjectForm(props) {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;
    const { viewOnly } = props
    const lineH = useRef(null);
    const [height, setHeight] = useState((window.innerHeight - 91) * 0.5);
    const [openContactsSearch, setOpenContactsSearch] = useState(false)
    const [openSearchBar, setOpenSearchBar] = useState(false);
    const [deletingObjects, setDeletingObjects] = useState(false);
    const [deletingContacts, setDeletingContacts] = useState(false);
    const [open, setOpen] = useState(false);

    const navigate = useNavigate();

    //If dont have a description
    if (!localStorage.descriptionInformation)
        navigate("./cdescription");

    const onContactTypeClick = ({ key }) => {
        dispatch(setSelectingContact(key));
    };

    const contactMenu = (
        <Menu
            onClick={onContactTypeClick}
            items={[
                { key: "Abrupto", label: "Abrupto" },
                { key: "Críptico", label: "Críptico" },
                { key: "De falha", label: "De falha" },
                { key: "Erosional", label: "Erosional" },
                { key: "Gradacional", label: "Gradacional" },
                { key: "Indefinido", label: "Indefinido" },
                { key: "Linha de seixos", label: "Linha de seixos" },
                { key: "Amalgamado", label: "Amalgamado" },
                { key: "Concordante", label: "Concordante" }
            ]}
        />
    );

    const handleMouseDown = (e) => {
        const lineStyle = lineH.current.style;
        const objectsCard = document.getElementById("objectsCard");
        const contactsCard = document.getElementById("contactsCard");
        const top = Math.floor(objectsCard.getBoundingClientRect().top);
        const objectsHeadHeight = objectsCard.children[0].getBoundingClientRect().height + 3;
        const contactsHeadHeight = objectsCard.children[0].getBoundingClientRect().height + 3;
        lineStyle.visibility = 'visible';
        lineStyle.top = (e.clientY >= window.innerHeight - contactsHeadHeight)
            ? lineStyle.top = window.innerHeight - contactsHeadHeight
            : (e.clientY - top) + 'px';

        const handleMouseMove = (e) => {
            console.log(e.clientY)
            if (e.clientY <= top + objectsHeadHeight)
                lineStyle.top = objectsHeadHeight + 'px';
            else if (e.clientY >= window.innerHeight - contactsHeadHeight)
                lineStyle.top = window.innerHeight - contactsHeadHeight;
            else
                lineStyle.top = (e.clientY - top) + 'px';
        }

        const handleMouseUp = (e) => {
            if (e.clientY <= top + objectsHeadHeight)
                setHeight(0)
            else if (e.clientY >= window.innerHeight - contactsHeadHeight)
                setHeight(window.innerHeight - top - 87)
            else
                setHeight(e.clientY - 41 - top)
            lineStyle.visibility = 'hidden';
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        e.preventDefault();
        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    const menuObjects = [
        <Tooltip title="Adicionar">
            <Button type="link" size="small"
                icon={<PlusOutlined style={{ display: "inline-flex" }} />}
                onClick={() => setOpen(true)}
            />
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button type="link"  size="small"
                icon={<SearchOutlined style={{ display: "inline-flex" }} />}
                onClick={() => { setOpenSearchBar(!openSearchBar) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link"  size="small"
                icon={<DeleteOutlined style={{ display: "inline-flex" }} />}
                onClick={() => { setDeletingObjects(!deletingObjects) }}
                disabled={(state.descriptionObjects.contacts.selectingContact !== null)}
            />
        </Tooltip>,

        <Divider type="vertical" style={{ top: 1, left: 1, background: 'black' }}/>,

        <Tooltip title="Contatos">
            <Dropdown overlay={contactMenu} trigger={['click']} placement="bottom" disabled={deletingObjects}>
                <Button icon={<AiOutlineColumnWidth />} type="link" style={{ top: -2 }}/>
            </Dropdown>
        </Tooltip> 
    ]

    const menuContacts = [
        <Tooltip title="Criar Contato">
            <Dropdown overlay={contactMenu} trigger={['click']} disabled={deletingObjects}>
                <Button
                    type="link"
                    size="small"

                    icon={<PlusOutlined style={{ display: "inline-flex" }}/>}
                    onClick={(e) => e.preventDefault()}>
                </Button>
            </Dropdown>
        </Tooltip>,

        <Tooltip title="Pesquisar">
            <Button 
                type="link"
                size="small"

                icon={<SearchOutlined style={{ display: "inline-flex" }}/>}
                onClick={() => { setOpenContactsSearch(!openContactsSearch) }}
            />
        </Tooltip>,

        <Tooltip title="Remover">
            <Button type="link"  size="small"
                icon={<DeleteOutlined style={{ display: "inline-flex" }} />}

                onClick={() => { setDeletingContacts(!deletingContacts) }}
            />
        </Tooltip>,
    ]

    return (
        <>
            <Card
                id="objectsCard"
                title="Objetos Geológicos"
                extra={viewOnly ? [] : menuObjects}
                size="small"
                bodyStyle={{
                    height: height,
                    padding: "1px",
                }}
            >
                <ObjectsTree viewOnly={viewOnly} height={height} openSearchBar={openSearchBar} deletingObjects={deletingObjects} />
            </Card>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: "row-resize",
                    backgroundColor: '#DCDCDC'
                }}
                onMouseDown={handleMouseDown}
            >
                <SmallDashOutlined
                    style={{
                        fontSize: '8px',
                        transform: 'scale(3, 1)',
                    }}
                />
            </div>

            <div id="line-H" ref={lineH}>
                <SmallDashOutlined
                    style={{
                        fontSize: '8px',
                        transform: 'scale(3, 1)',
                    }}
                />
            </div>

            <Card
                id="contactsCard"
                title="Contatos"
                size="small"
                extra={viewOnly ? [] : menuContacts}
                bodyStyle={{
                    overflowY: 'auto',
                    height: (window.innerHeight - 91) - height,
                }}
            >
                <ContactsForm viewOnly={viewOnly} openContactsSearch={openContactsSearch} deletingContacts={deletingContacts}/>
            </Card>

            {open && <AddObjectModal setOpen={setOpen} parentRef={null} /> }
        </>
    );
}

export default GeoObjectForm;