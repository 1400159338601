import { React, useContext } from 'react';
//import { Link } from 'react-router-dom';
import DescriptionTable from './DescriptionTable';
import { store, initialState } from '../Store';
import { setDescriptionInformationAction, setDescriptionObjectsAction } from '../Store/actions';
import { Space, Button, Layout, Breadcrumb, Row, Col, Input, Affix, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons';

const { Content } = Layout;
const { Search } = Input;
const { Link } = Typography;


function Description() {
    const globalState = useContext(store);
    const { state, dispatch } = globalState;

    return (
        <>
            <Content style={{ padding: '0 100px 50px 100px' }}>
                <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
                    <Breadcrumb>
                        <Breadcrumb.Item href="/">
                            <Link>Home </Link>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>
                            Busca de Análogos
                        </Breadcrumb.Item>
                    </Breadcrumb>

                    <DescriptionTable />
                </Space>
            </Content>
        </>
    );
}


export default Description;